@font-face {
    font-family: 'Poppins-Bold';
    src: url(./assets/fonts/Poppins-Bold.ttf);
}
@font-face {
    font-family: 'Poppins-SemiBold';
    src: url(./assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url(./assets/fonts/Poppins-BoldItalic.ttf);
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url(./assets/fonts/Poppins-Italic.ttf);
}
@font-face {
    font-family: 'Poppins-Medium';
    src: url(./assets/fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: 'Poppins-Light';
    src: url(./assets/fonts/Poppins-Light.ttf);
}

@font-face {
    font-family: 'Poppins-LightItalic';
    src: url(./assets/fonts/Poppins-LightItalic.ttf);
}

@font-face {
    font-family: 'Tajawal-Light';
    src: url(./assets/fonts/Tajawal-Light.ttf);
}

@font-face {
    font-family: 'Tajawal-Medium';
    src: url(./assets/fonts/Tajawal-Medium.ttf);
}

@font-face {
    font-family: 'Tajawal-Regular';
    src: url(./assets/fonts/Tajawal-Regular.ttf);
}

@font-face {
    font-family: 'Tajawal-Bold';
    src: url(./assets/fonts/Tajawal-Bold.ttf);
}
@font-face {
    font-family: 'Tajawal-Black';
    src: url(./assets/fonts/Tajawal-Black.ttf);
}

@font-face {
    font-family: 'Tajawal-ExtraBold';
    src: url(./assets/fonts/Tajawal-ExtraBold.ttf);
}
@font-face {
    font-family: 'Tajawal-ExtraLight';
    src: url(./assets/fonts/Tajawal-ExtraLight.ttf);
}
body{
    background-color: #2E373F;
    /* height: 110vh; */
    margin: 0;

}
.emptySpace{
    width:70%;
}
.modalView{
    display: none;
}
@media only screen and (max-width: 900px) {
    .emptySpace{
        display: none;
    }
}